(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAuth.controller:AdminLoginCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAuth')
    .controller('AdminLoginCtrl', AdminLoginCtrl);

  function AdminLoginCtrl(Auth, FormControl, _, $location, $state, $rootScope, SessionService) {
    var vm = this
      , formControl = new FormControl();

    vm.session = {
      identifier: '',
      password: '',
      clientEmail: ''
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      setAppTransitioning();
      vm.loginForm.$setSubmitted();
      $location.setNextUrl($state.get('companySelector').url);

      formControl
        .process(Auth.adminLogin(vm.session))
        .then(SessionService.setSessionTimeout())
        .catch(clearAppTransitioning);

      function setAppTransitioning() {
        $rootScope.appIsTransitioning = true;
      }

      function clearAppTransitioning() {
        $rootScope.appIsTransitioning = false;
      }
    };
  }
}());
